import React, { useEffect, useState, useContext } from 'react';
import { useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import socket from '../socket';
import { useLoading } from '../LoadingContext';
import LoadingScreen from './LoadingScreen';
import { SessionContext } from '../SessionContext'; // Import SessionContext
import './Lobby.css';


const Lobby = () => {
  const { lobbyCode } = useParams();
  const navigate = useNavigate();
  const { isLoading, showLoading, hideLoading } = useLoading();
  const { sessionLost, alertMessage, resetSession } = useContext(SessionContext); // Access session context

  const [lobby, setLobby] = useState({
    game: null,
    players: [], // Initialize as empty array
    spectators: [], // Initialize as empty array
    lobbyCode: '',
    status: 'waiting',
  });

  const [countdown, setCountdown] = useState(null); // Countdown state
  const [gameMode, setGameMode] = useState('classic'); // Default to classic mode
  const [imposter, setImposter] = useState(null);
  const [imposterGuess, setImposterGuess] = useState(''); // For tracking the hotseat's imposter guess


  const [message, setMessage] = useState('');
  const [loadingComplete, setLoadingComplete] = useState(false);
  const [isSpectator, setIsSpectator] = useState(false);
  const [isHost, setIsHost] = useState(false); // Track if the current player is the host
  const [hotseat, setHotseat] = useState(null); // Track the current hotseat player
  const [secretNumber, setSecretNumber] = useState(null); // Track the secret number
  const [roundNumber, setRoundNumber] = useState(0); // Track the current round number
  const [questioningPlayer, setQuestioningPlayer] = useState(''); // Track the player being questioned
  const [question, setQuestion] = useState(''); // Track the question being asked
  const [remainingPlayers, setRemainingPlayers] = useState([]); // Track the remaining players to be questioned
  const [currentQuestion, setCurrentQuestion] = useState(''); // Track the current question for answering phase
  const [answer, setAnswer] = useState(''); // Track the answer being provided
  const [controlsDisabled, setControlsDisabled] = useState(false);
  const [currentAnswer, setCurrentAnswer] = useState('');
  const [gamePhase, setGamePhase] = useState('questioning'); // Track the game phase
const [hotseatNickname, setHotseatNickname] = useState(''); // Track hotseat player's name

const [tooltipVisible, setTooltipVisible] = useState(false); // Manage tooltip visibility

const [showNicknameModal, setShowNicknameModal] = useState(false);
const [newNickname, setNewNickname] = useState('');

const chatEndRef = useRef(null);



  // Add a state for the hotseat player's guess
const [guess, setGuess] = useState(1); // Default guess is 1

  

const [chatMessages, setChatMessages] = useState([
  {
    type: 'system',
    text: `Welcome to the game! Joined room ${lobbyCode}`,
    style: { color: 'yellow', textAlign: 'center' }, // Custom styles
  },
]);

  const playerId = localStorage.getItem('playerId');

  const scrollToBottom = () => {
    if (chatEndRef.current) {
      chatEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [chatMessages]); // Run whenever chatMessages change

  useEffect(() => {

    console.log('Initial lobby state:', lobby);
    console.log('Initial isHost:', isHost);
    console.log('Initial lobby.players.length:', lobby.players.length);

    showLoading();

    const fetchLobby = async () => {
      try {
        const response = await fetch(`https://wavelengthau.com/api/lobbies/${lobbyCode}`);
        const data = await response.json();
    
        if (!response.ok) {
          setMessage(data.message || 'Failed to fetch lobby');
          localStorage.removeItem('lobbyCode');
          setTimeout(() => navigate('/'), 3000);
          return;
        }
    
        setLobby(data);
    
        let playerId = localStorage.getItem('playerId');
        if (!playerId) {
          console.log('[Lobby.js] playerId not found. Requesting a new player ID.');
          socket.emit('getOrCreatePlayer', {}, ({ playerId: newPlayerId }) => {
            localStorage.setItem('playerId', newPlayerId);
            console.log('[Lobby.js] Emitting joinLobby with new playerId:', newPlayerId);
    
            // Emit joinLobby once
            if (!socket.state?.joinedLobby) {
              socket.emit('joinLobby', { lobbyCode, playerId: newPlayerId });
              socket.state = { joinedLobby: true };
            }
    
            updateLobbyState(lobbyCode);
          });
        } else {
          console.log('[Lobby.js] Emitting joinLobby with existing playerId:', playerId);
    
          // Emit joinLobby once
          if (!socket.state?.joinedLobby) {
            socket.emit('joinLobby', { lobbyCode, playerId });
            socket.state = { joinedLobby: true };
          }
    
          updateLobbyState(lobbyCode);
        }
      } catch (error) {
        console.error('[fetchLobby] Error fetching lobby:', error);
        setMessage('Failed to fetch lobby');
        localStorage.removeItem('lobbyCode');
        setTimeout(() => navigate('/'), 3000);
      } finally {
        hideLoading();
      }
    };
        
    
    // Helper function to fetch the latest lobby state and update UI
    const updateLobbyState = async (lobbyCode) => {
      try {
        const updatedLobbyResponse = await fetch(`https://wavelengthau.com/api/lobbies/${lobbyCode}`);
        const updatedLobbyData = await updatedLobbyResponse.json();
    
        if (updatedLobbyResponse.ok) {
          setLobby(updatedLobbyData);
          setGamePhase(updatedLobbyData.status);
          setIsHost(updatedLobbyData.host?.uuid === localStorage.getItem('playerId'));
          setGamePhase(updatedLobbyData.status === 'finished' ? 'finished' : 'waiting');
          console.log('[Lobby.js] Lobby state updated:', updatedLobbyData);
        } else {
          console.error('[Lobby.js] Failed to fetch updated lobby after join.');
        }
      } catch (error) {
        console.error('[updateLobbyState] Error fetching updated lobby:', error);
      }
    };
    
    // Call fetchLobby to initialize
    fetchLobby();
    

    socket.on('playerData', ({ playerId }) => {
      localStorage.setItem('playerId', playerId);
    });

    socket.on('chatMessage', (message) => {
      setChatMessages((prevMessages) => [...prevMessages, message]);
  });

    socket.on('lobbyUpdated', (updatedLobby) => {
      // Validate updatedLobby data
      if (!updatedLobby || !updatedLobby.players || !updatedLobby.host) {
        console.warn('Incomplete lobby data received:', updatedLobby);
        return;
      }
    
      // Avoid redundant state updates
      setLobby((prevLobby) => {
        if (JSON.stringify(prevLobby) === JSON.stringify(updatedLobby)) {
          console.log('No significant changes detected in lobby update.');
          return prevLobby;
        }
        console.log('Updating lobby state...');
        return updatedLobby;
      });

      setGamePhase(updatedLobby.status === 'finished' ? 'finished' : 'waiting');
    
      // Update game-related states conditionally
      if (updatedLobby.game) {
        setHotseat(updatedLobby.game.hotseat || null);
        setRoundNumber(updatedLobby.game.roundsPlayed || 0);
    
        console.log(`[lobbyUpdated] Lobby data received:`, updatedLobby);
        console.log(`[lobbyUpdated] Current playerId: ${playerId}`);
        console.log(`[lobbyUpdated] Game secret number: ${updatedLobby.game?.secretNumber || 'N/A'}`);
      

        // Determine if the current player is the imposter or hotseat player
        const isImposter = updatedLobby.game.imposter?.uuid === playerId;
        const isHotseat = updatedLobby.game.hotseat?.uuid === playerId;

        console.log(`[lobbyUpdated] Is current player the hotseat? ${isHotseat}`);
        console.log(`[lobbyUpdated] Is current player the imposter? ${isImposter}`);
    
        // Always hide the secret number for the hotseat and imposter
        setSecretNumber(isImposter || isHotseat ? null : updatedLobby.game.secretNumber);
    
        // Determine and set the imposter
        if (updatedLobby.game.imposter) {
          const currentImposter = updatedLobby.players.find(
            (p) => p.uuid === updatedLobby.game.imposter.uuid
          );
          setImposter(currentImposter || null);
        } else {
          setImposter(null);
        }
    
        // Determine remaining players
        const remaining = updatedLobby.players.filter(
          (p) =>
            !updatedLobby.game.answeredPlayers.includes(p.uuid) &&
            p.uuid !== updatedLobby.game.hotseat?.uuid
        );
        setRemainingPlayers(remaining);
      } else {
        console.log('No active game detected. Resetting game-related states.');
        setHotseat(null);
        setSecretNumber(null);
        setRoundNumber(0);
        setRemainingPlayers([]);
        setImposter(null); // Reset imposter when no active game
      }
    
      // Update host role
      const newIsHost = updatedLobby.host?.uuid === playerId;
      if (newIsHost !== isHost) {
        setIsHost(newIsHost);
      }
    
      // Update spectator role
      const newIsSpectator =
        !!updatedLobby.spectators?.find((s) => s.uuid === playerId) &&
        !updatedLobby.players?.find((p) => p.uuid === playerId);
      if (newIsSpectator !== isSpectator) {
        console.log('Updated isSpectator:', newIsSpectator);
        setIsSpectator(newIsSpectator);
      }
    });
    
    
    
    socket.on('roundStarted', ({ roundNumber, hotseatPlayer, secretNumber }) => {
      console.log(`[roundStarted] Round ${roundNumber + 1} started with hotseat player: ${hotseatPlayer.nickname}`);
      console.log(`[roundStarted] Round ${roundNumber + 1} started`);
  console.log(`[roundStarted] Hotseat player: ${hotseatPlayer.nickname}`);
  console.log(`[roundStarted] Received secret number: ${secretNumber || 'N/A'}`);

      // Update the hotseat state
      setHotseat(hotseatPlayer);
    
      // Determine if the current player is the imposter or hotseat player
      const isImposter = imposter && imposter.uuid === playerId;
      const isHotseat = hotseatPlayer && hotseatPlayer.uuid === playerId;

      console.log(`[roundStarted] Is current player the hotseat? ${isHotseat}`);
      console.log(`[roundStarted] Is current player the imposter? ${isImposter}`);
    
      // Hide secret number for hotseat and imposter
      setSecretNumber(isImposter || isHotseat ? null : secretNumber);
    
      // Update the round number and remaining players
      setRoundNumber(roundNumber);
      setRemainingPlayers(
        lobby.players.filter(
          (p) => p.uuid !== hotseatPlayer.uuid && !lobby.game.answeredPlayers.includes(p.uuid)
        )
      );
    });

    console.log('[Lobby.js] Listening for questionReceived event');

  socket.on('questionReceived', ({ question, questioningPlayerNickname }) => {
    console.log(`[questionReceived] Received question from ${questioningPlayerNickname}: ${question}`);
    setCurrentQuestion(`${questioningPlayerNickname} asked: ${question}`); // Update UI
  });

    socket.on('enableControls', ({ message }) => {
      console.log('[Lobby.js] Controls enabled for hotseat:', message);
      setControlsDisabled(false); // Enable the controls for the hotseat player
    });

    socket.on('disableControls', ({ message }) => {
      console.log('[Lobby.js] Controls disabled:', message);
      setControlsDisabled(true);
    });

    socket.on('answerReceived', async ({ answer, playerNickname }) => {
      console.log(`[answerReceived] Answer received from ${playerNickname}: ${answer}`);
      console.log(`[answerReceived] Current playerId: ${playerId}`);
    
      try {
        // Fetch the latest lobby data
        const response = await fetch(`https://wavelengthau.com/api/lobbies/${lobbyCode}`);
        const updatedLobby = await response.json();
    
        if (!response.ok || !updatedLobby || !updatedLobby.players) {
          console.error('[fetchLobby] Failed to fetch lobby or players missing.');
          return;
        }
    
        const localPlayer = updatedLobby.players.find((p) => p.uuid === playerId);
        const isSelf = localPlayer && localPlayer.nickname === playerNickname;
    
        console.log('[answerReceived] Local player:', localPlayer);
        console.log('[answerReceived] Is message from self?', isSelf);
    
        setChatMessages((prevMessages) => [
          ...prevMessages,
          {
            type: 'player',
            sender: isSelf ? 'self' : 'other',
            text: `${playerNickname}: ${answer}`,
          },
        ]);
      } catch (error) {
        console.error('[answerReceived] Error fetching lobby:', error);
      }
    });
    
    socket.on('questionBroadcast', async ({ question, questioningPlayerNickname, questionedPlayerNickname }) => {
      console.log(`[questionBroadcast] ${questioningPlayerNickname} -> ${questionedPlayerNickname}: ${question}`);
      console.log(`[questionBroadcast] Current playerId: ${playerId}`);
    
      try {
        // Fetch the latest lobby data
        const response = await fetch(`https://wavelengthau.com/api/lobbies/${lobbyCode}`);
        const updatedLobby = await response.json();
    
        if (!response.ok || !updatedLobby || !updatedLobby.players) {
          console.error('[fetchLobby] Failed to fetch lobby or players missing.');
          return;
        }
    
        const localPlayer = updatedLobby.players.find((p) => p.uuid === playerId);
        const isSelf = localPlayer && localPlayer.nickname === questioningPlayerNickname;
    
        console.log('[questionBroadcast] Local player:', localPlayer);
        console.log('[questionBroadcast] Is message from self?', isSelf);
    
        setChatMessages((prevMessages) => [
          ...prevMessages,
          {
            type: 'player',
            sender: isSelf ? 'self' : 'other',
            text: `${questioningPlayerNickname} -> ${questionedPlayerNickname}: ${question}`, // Updated format
          },
        ]);
      } catch (error) {
        console.error('[questionBroadcast] Error fetching lobby:', error);
      }
    });
    
    
    
    socket.on('phaseChanged', ({ phase, hotseatNickname, candidates }) => {
      console.log(`[phaseChanged] Phase changed to: ${phase}`);
      setGamePhase(phase); // Update the game phase
      setHotseatNickname(hotseatNickname || 'Unknown Player');
  
      if (phase === 'questioning') {
          console.log('Transitioning to questioning phase...');
      }
  
      if (phase === 'guessing') {
          console.log(`${hotseatNickname || 'Unknown Player'} is about to guess.`);
          setChatMessages((prev) => [
              ...prev,
          ]);
      }
  
      if (phase === 'guessTheImposter') {
        console.log(`${hotseatNickname || 'Unknown Player'} is guessing the imposter.`);
        setChatMessages((prev) => [
          ...prev,
          { type: 'system', text: `${hotseatNickname || 'Unknown Player'} is guessing the imposter.` },
        ]);
    
        if (candidates) {
          setRemainingPlayers(candidates); // Set the list of players excluding the hotseat
        }
      }
  });
  

    socket.on('guessResult', ({ nickname, guessedNumber, isCorrect }) => {
      setChatMessages((prevMessages) => [
        ...prevMessages,
      ]);
    });
  
    socket.on('gameFinished', ({ message }) => {
      console.log('[gameFinished] Game has finished:', message);
      setGamePhase('finished');
      setHotseat(null);
      setSecretNumber(null);
      setChatMessages((prevMessages) => [
        ...prevMessages,
        { type: 'system', text: message },
      ]);
    });

      // Listen for the startCountdown event from the server
  socket.on('startCountdown', () => {
    console.log('Countdown started for all players.');
    setCountdown(5); // Set the countdown to 5 seconds

    const countdownInterval = setInterval(() => {
      setCountdown((prevCountdown) => {
        if (prevCountdown === 1) {
          clearInterval(countdownInterval); // Clear interval when countdown ends
          setCountdown(null); // Hide the countdown display
        }
        return prevCountdown - 1; // Decrement the countdown
      });
    }, 1000); // Update every second
  });
  
  socket.on('gameModeUpdated', ({ gameMode }) => {
    setGameMode(gameMode);
  });

  socket.on('roleNotification', ({ role, message }) => {
    if (role === 'imposter') {
      alert(message); // Simple alert for now, can replace with a styled modal
    }
  });
    
  return () => {
    console.log('Lobby.js unmounting');
    socket.off('playerData');
    socket.off('lobbyUpdated');
    socket.off('roundStarted');
    socket.off('questionReceived');
    socket.off('disableControls');
    socket.off('answerReceived');
    socket.off('questionBroadcast');
    socket.off('phaseChanged');
    socket.off('enableControls');
    socket.off('guessResult');
    socket.off('startCountdown');
    socket.off('gameFinished');
    socket.off('gameModeUpdated');
    socket.off('roleNotification');
  };
  }, [lobbyCode]);

  
  const copyToClipboard = () => {
    const lobbyLink = `${window.location.origin}/room/${lobby.lobbyCode}`;
    navigator.clipboard.writeText(lobbyLink)
      .then(() => {
        console.log('Lobby link copied to clipboard');
        alert('Lobby link copied to clipboard!'); // Optional: replace with a custom notification
      })
      .catch((err) => {
        console.error('Failed to copy text: ', err);
      });
  };
  

  const handleStartGame = () => {
    console.log(`Handling the start of the game.`);
  
    const playerId = localStorage.getItem('playerId');
    if (!playerId) {
      console.error('Player ID not found');
      return;
    }
  
    const player = lobby.players.find((p) => p.uuid === playerId);
    if (!player) {
      console.error('Player not found in the lobby.');
      return;
    }
  
    console.log(`Starting countdown before game as player: ${player.nickname}`);
  
    // Notify all players to start the countdown
    socket.emit('startCountdown', { lobbyCode });
  
    setTimeout(() => {
      // Emit startGame event after countdown finishes
      console.log(`Starting Game as player: ${player.nickname}`);
      socket.emit('startGame', { lobbyCode, playerId, gameMode });
  
      // Add a listener for errors if the server doesn't handle the request
      socket.once('error', (err) => {
        console.error('Error starting game:', err.message);
      });
    }, 5000); // Wait for the 5-second countdown
  };

  const handleImposterGuessSubmit = () => {
    const playerId = localStorage.getItem('playerId'); // Current player
    if (imposterGuess) {
        socket.emit('submitImposterGuess', { lobbyCode, imposterGuessId: imposterGuess, playerId });
        console.log(`Imposter guess submitted: ${imposterGuess}`);
    } else {
        console.warn('No imposter selected!');
    }
};

const handlePlayAgain = () => {
  socket.emit('playAgain', { lobbyCode });
  setChatMessages([]); // Clear chat messages
};
  

  const handleNextRound = () => {
    console.log(`Handling the next round.`);
  
    const nextRoundNumber = roundNumber + 1;
    console.log(`Next round number: ${nextRoundNumber}`);
  
    socket.emit('nextRound', { lobbyCode, roundNumber: nextRoundNumber });
  
    // Add a listener for errors if the server doesn't handle the request
    socket.once('error', (err) => {
      console.error('Error starting next round:', err.message);
    });
  };

  const handleNicknameChange = () => {
    const playerId = localStorage.getItem('playerId');
    if (newNickname.trim().length < 3) {
      alert("Nickname must be at least 3 characters long.");
      return;
    }
    socket.emit('updateNickname', { playerId, newNickname });
    setShowNicknameModal(false);
  };

  const handleToggleSpectate = () => {
    const playerId = localStorage.getItem('playerId');
    socket.emit('toggleSpectate', { playerId, lobbyCode });
  };

  const handleReturnToHomepage = () => {
    console.log('Return to Homepage button clicked');
    const playerId = localStorage.getItem('playerId');
    socket.emit('removePlayerFromLobby', { playerId, lobbyCode });
    navigate('/');
  };

  const handleAnimationEnd = () => {
    setLoadingComplete(true);
  };

  const handleQuestionSubmit = () => {
    if (questioningPlayer && question) {
      socket.emit('submitQuestion', { lobbyCode, questioningPlayer, question });
      setQuestioningPlayer(''); // Reset the questioning player
      setQuestion(''); // Reset the question
    }
  };

  const handleRandomizeQuestion = async () => {
    try {
      const response = await fetch('/randomQuestions.json'); // Adjust path if necessary
      if (!response.ok) {
        throw new Error('Failed to fetch random questions');
      }
      const questions = await response.json();
      const randomQuestion = questions[Math.floor(Math.random() * questions.length)];
      setQuestion(randomQuestion);
    } catch (error) {
      console.error('Error fetching random questions:', error);
    }
  };
  

  const handleAnswerSubmit = () => {
    const playerId = localStorage.getItem('playerId'); // Retrieve playerId from localStorage
    if (answer && playerId) {
      socket.emit('submitAnswer', { lobbyCode, answer, playerId });
      console.log('handleAnswerSubmit', {imposter});
      setAnswer(''); // Reset the answer input field
      setCurrentQuestion(''); // Clear the current question
    }
  };

  const handleGuessSubmit = () => {
    const playerId = localStorage.getItem('playerId'); // Retrieve playerId from localStorage
    if (guess && playerId) {
      setControlsDisabled(true); // Disable the button immediately
      socket.emit('submitGuess', { lobbyCode, guess, playerId });
  
      console.log(`Guess submitted: ${guess}`);
      setImposterGuess('');
    } else {
      console.warn('Guess or player ID is missing!');
    }
  };
  const handleGameModeChange = (newMode) => {
    if (isHost) {
      socket.emit('changeGameMode', { lobbyCode, gameMode: newMode });
      console.log(`Game mode changed to: ${newMode}`);
      setGameMode(newMode); // Update local state
    } else {
      console.log('Only the host can change the game mode.');
    }
  };
  
  
  

  return (
    <div className="lobby-app">
      {sessionLost ? ( // Session lost overlay
        <div className="overlay">
          <div className="modal">
            <p>{alertMessage}</p>
            <button onClick={() => resetSession() || window.location.reload()}>Refresh</button>
          </div>
        </div>
      ) : isLoading && !loadingComplete ? (
        <LoadingScreen onAnimationEnd={handleAnimationEnd} />
      ) : (
        <div className={`content ${loadingComplete ? 'fade-in' : ''}`}>
          <div className="wavelength-title">Wavelength</div>

          {countdown !== null && (
  <div className="countdown-overlay">
    <div className="countdown-number" key={countdown}>
      {countdown}
    </div>
  </div>
)}

          <div className="game-controls-container">
          <h2>Game Controls</h2>

          {gamePhase === 'finished' && (
        <div className="finished-phase">
          <p>Game Over. Thank you for playing!</p>
          {isHost && (
            <button className="play-again-button" onClick={handlePlayAgain}>
              Play Again
            </button>
          )}
        </div>
      )}

            {lobby.status === 'waiting' && (
    <div className="waiting-phase">
      <p>Waiting for the host to start the game...</p>
      {isHost && lobby.players.length >= 2 && (
        <button className="lobby-button start-game-button" onClick={handleStartGame}>
          Start Game
        </button>
      )}
    </div>
  )}

          {gamePhase === 'guessing' ? (
  // Guessing Phase
  hotseat && hotseat.uuid === playerId ? (
    // For Hotseat Player
    <div className="guessing-phase">
      <h3 className="guessing-title">Make your guess!</h3>
      <div className="guess-slider-container">
        <input
          type="range"
          min="1"
          max="10"
          value={guess || 5} // Use 'guess' state instead of 'secretNumber'
          onChange={(e) => setGuess(Number(e.target.value))} // Update 'guess' state
          className="guess-slider"
        />
        <div className="guess-display">
          Your Guess: <span>{guess}</span>
        </div>
      </div>
      <button className="guess-submit-button" onClick={handleGuessSubmit}>
        Submit Guess
      </button>
    </div>
  ) : (
    // For Other Players
    <div className="guessing-phase">
      <p>{hotseatNickname} is making their guess!</p>
    </div>
  )
) : gamePhase === 'guessTheImposter' ? (
// Guess The Imposter Phase
hotseat && hotseat.uuid === playerId ? (
  // For Hotseat Player
  <div className="guess-imposter-phase">
    <h3 className="guess-imposter-title">Guess the Imposter!</h3>
    <div className="imposter-dropdown-container">
      <select
        value={imposterGuess || ''}
        onChange={(e) => setImposterGuess(e.target.value)} // Set the selected imposter
        className="imposter-dropdown"
      >
        <option value="" disabled>
          Select a player
        </option>
        {remainingPlayers.map((player) => (
          <option key={player.uuid} value={player.uuid}>
            {player.nickname}
          </option>
        ))}
      </select>
    </div>
    <button className="imposter-submit-button" onClick={handleImposterGuessSubmit}>
      Submit Guess
    </button>
  </div>
) : (
  // For Other Players
  <div className="guess-imposter-phase">
    <p>{hotseatNickname} is guessing the imposter!</p>
  </div>
)
// Fallback when no conditions are met

    // Questioning Phase
) : hotseat && hotseat.uuid === playerId ? (
      <div className="questioning-phase">
  <p>You are in the hotseat!</p>
  {controlsDisabled ? (
    <p>Waiting for the questioned player's response...</p>
  ) : (
    <div className="input-container">
  {/* Line 1: Dropdown */}
  <select
    value={questioningPlayer}
    onChange={(e) => setQuestioningPlayer(e.target.value)}
    disabled={controlsDisabled}
  >
    <option value="">Select a player</option>
    {remainingPlayers.map((player) => (
      <option key={player.uuid} value={player.uuid}>
        {player.nickname}
      </option>
    ))}
  </select>

  {/* Line 2: Input Field and Randomize Button */}
  <div className="input-with-icon">
    <input
      type="text"
      value={question}
      onChange={(e) => setQuestion(e.target.value)}
      placeholder="Type your question here"
      disabled={controlsDisabled}
    />
    <button
      type="button"
      className="randomize-button"
      onClick={handleRandomizeQuestion}
      disabled={controlsDisabled}
    >
      <i className="fas fa-random"></i>
    </button>
  </div>

  {/* Line 3: Submit Button */}
  <button
    className="submit-button"
    onClick={handleQuestionSubmit}
    disabled={controlsDisabled}
  >
    Submit
  </button>
</div>

  )}
</div>

) : currentQuestion ? (
  <div className="answering-phase">
    <h3>Answer the Question</h3>
    <p className="question">{currentQuestion}</p>
    <input
      type="text"
      value={answer}
      onChange={(e) => setAnswer(e.target.value)}
      placeholder="Type your answer here"
    />
    <button onClick={handleAnswerSubmit}>Submit Answer</button>
  </div>
) : lobby.status === 'in-progress' && // Check if the lobby is in progress
  hotseat?.uuid !== playerId && // Ensure the current player is not the hotseat
  questioningPlayer !== playerId && // Ensure the current player is not being questioned
  !currentQuestion && ( // Ensure there is no current question
  <div className="waiting-box">
    {hotseatNickname} is preparing their question
  </div>
)
}

</div>

          {/* Game Chat */}
          <div className="game-chat-container">
          <div className="chat-messages">
  {chatMessages.map((message, index) => {
    // Format for messages containing "->"
    if (message.text.includes("->")) {
      const [questioning, rest] = message.text.split("->");
      const [questioned, question] = rest.split(":");
      return (
        <div
          key={index}
          className={`chat-message ${message.type} ${
            message.sender === 'self' ? 'chat-message-self' : 'chat-message-other'
          }`}
        >
          <span className="chat-message-player-name">{questioning.trim()}</span>
          {" -> "}
          <span className="chat-message-player-name">{questioned.trim()}</span>
          {`: ${question}`}
        </div>
      );
    }

    // Format for player answer messages
    if (message.text.includes(":")) {
      const [player, answer] = message.text.split(":");
      return (
        <div
          key={index}
          className={`chat-message ${message.type} ${
            message.sender === 'self' ? 'chat-message-self' : 'chat-message-other'
          }`}
        >
          <span className="chat-message-player-name">{player.trim()}</span>
          {`: ${answer}`}
        </div>
      );
    }

    // Default message format
    return (
      <div
        key={index}
        className={`chat-message ${message.type} ${
          message.sender === 'self' ? 'chat-message-self' : 'chat-message-other'
        }`}
      >
        {message.text}
      </div>
    );
  })}
      <div ref={chatEndRef} />
      </div>
</div>


          {/* Display the secret number */}
          <div className="secretnumber-container">
            <p className="secretnumber-label">Secret Number:</p>
            <div className="secretnumber">
  {hotseat && hotseat.uuid === playerId || (imposter && imposter.uuid === playerId)
    ? "?"
    : secretNumber || "?"}
</div>
</div>
          {lobby ? (
            <div className="lobbyinfo-container">
              <div className="lobby-header">
              <div
  className="lobby-code-container"
  onMouseEnter={() => setTooltipVisible(true)} // Show tooltip on hover
  onMouseLeave={() => setTooltipVisible(false)} // Hide tooltip when hover ends
  onClick={copyToClipboard} // Copy the link on click
>
  <h1 className="lobby-code">{lobby.lobbyCode}</h1>
  {tooltipVisible && <div className="tooltip">Click to copy</div>}
</div>

                <p className="lobby-status">Status: {lobby.status}</p>
                {/* Show Start Game button only if the current player is the host */}
                {isHost && lobby.status === 'waiting' && lobby.players.length >= 2 && (
  <button
    className="lobby-button start-game-button"
    onClick={handleStartGame}
  >
    Start Game
  </button>
)}
              </div>
              <div className="game-mode-toggle">
  <p>Current Game Mode: {gameMode === 'classic' ? 'Classic Mode' : 'Imposter Mode'}</p>
  {isHost && lobby.status === 'waiting' ? (
    <button 
      className="mode-toggle-button" 
      onClick={() => handleGameModeChange(gameMode === 'classic' ? 'imposter' : 'classic')}
    >
      Switch to {gameMode === 'classic' ? 'Imposter Mode' : 'Classic Mode'}
    </button>
  ) : (
    <p>Only the host can toggle the game mode when the lobby is in waiting status.</p>
  )}
</div>



              <div className="player-section">
                <h2>Players</h2>
                <ul className="player-list">
                  {lobby?.players?.map((player) => (
                    <li className="player-list-item" key={player._id}>
                      {player.nickname || 'Unnamed Player'}
                    </li>
                  )) || <p>No players in the lobby.</p>}
                </ul>
              </div>
              <div className="spectator-section">
                <h2>Spectators</h2>
                <ul className="spectator-list">
                  {lobby?.spectators?.map((spectator) => (
                    <li className="spectator-list-item" key={spectator._id}>
                      {spectator.nickname || 'Unnamed Spectator'}
                    </li>
                  )) || <p>No spectators in the lobby.</p>}
                </ul>
              </div>
              <div className="lobby-button-container">

              <button onClick={() => setShowNicknameModal(true)} className="lobby-button">
        Change Nickname
      </button>
      
      {showNicknameModal && (
        <div className="overlay">
          <div className="modal">
            <h3>Change Your Nickname</h3>
            <input
              type="text"
              placeholder="Enter new nickname"
              value={newNickname}
              onChange={(e) => setNewNickname(e.target.value)}
            />
            <button onClick={handleNicknameChange}>Change Nickname</button>
            <button onClick={() => setShowNicknameModal(false)}>Cancel</button>
          </div>
        </div>
      )}

                {lobby.status !== 'in-progress' && (
                  <button
                    className="lobby-button toggle-role-button"
                    onClick={handleToggleSpectate}
                  >
                    {isSpectator ? 'Join Game' : 'Spectate'}
                  </button>
                )}
                <button
                  className="lobby-button"
                  onClick={handleReturnToHomepage}
                >
                  Return to Homepage
                </button>
              </div>
            </div>
          ) : (
            <p>{message}</p>
          )}
        </div>
      )}
    </div>
  );
};

export default Lobby;